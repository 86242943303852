.services-div {
    padding: 452px 65px 65px 65px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.service-heading {
    font-size: 40px;
    font-weight: bold;
}

.service-box {
    position: relative;
    width: 22.5%;
    padding: 10px 0 0;
    /* margin-left: 1.55%; */
    border: solid 1px #fff;
    float: left;
    /* display: table-row; */
    transition: all .4s;
    border-radius: 6px;
    border: solid 1px #eee;
    text-align: center;


}



.service-box:hover {
    box-shadow: 0 0.625rem 0.875rem -0.3125rem #e0e0e0;
}

.services-text {
    padding: 15px 20px 20px 20px;
}

.silver-contnr {
    position: relative;
    /* width: 97%; */
    float: left;
    padding: 15px 20px;
    background: #f5f5f5;

}

.about-images {
    width: 100%;
    height: 400px;
    top: 79px;
    position: absolute;
    background: url("../../../src//assets/programming-background-with-person-working-with-codes-computer\ \(1\)\ \(2\).jpg")center center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-images::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    z-index: 1;
}

.about-images .content {
    position: relative;
    z-index: 2;
    color: white; /* Adjust text color as needed */
    /* Add other styles for the content inside the overlay */
}

.process {
    position: relative;
    width: 90%;
    height: 627px;
    padding-left: 10%;
    background: transparent url("https://cdn3.quintetsolutions.com/img/services/scale.png") repeat-y top left;
    margin: 100px 0px;
    float: left;
}

.step {
    position: absolute;
    width: 95%;
    left: 5%;
    height: 100px;


}

.two {
    top: 101px;

}

.three {
    top: 224px;
}

.four {
    top: 349px;
}

.five {
    top: 473px
}

.bullet {

    background: black;
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border-radius: 70px;
    font: 300 30px/50px 'Montserrat', sans-serif;
    font-style: normal;
    color: #fff;
    text-align: center;
}

.heading {
    padding: 15px 15px 0 15px;
}

.heading-step {
    position: relative;
    width: 80%;
    left: 8%;
    margin-top: -3px;
    font: normal 500 24px/30px 'Montserrat', sans-serif;
    color: #000;
    float: left;
}

.p-step {
    position: relative;
    width: 92%;
    left: 8%;
    color: #222;
    float: left;
}

.service-text {
    padding: 0 150px;
}

.img-div {
    padding: 20px 5px 5px 5px;
}

@media only screen and (max-width: 1200px) {

    .service-box {
        width: 24.2%;
    }

    .services-text {
        padding: 10px 20px 20px 20px;
        font-size: 13px;
    }

    .heading {
        font-size: 20px;
        padding: 20px 20px 0 20px;

    }

    .service-text {
        padding: 0;
    }

}

@media only screen and (max-width: 1100px) {
    .heading-step {
        width: 88%;
        margin-top: 11px;
        font: normal 500 22px/20px 'Montserrat', sans-serif;
    }
    .p-step{
        font-size: 14px;
    }
}

@media only screen and (max-width: 800px) {
    .services-text {
        padding: 12px;
        font-size: 10px;
    }

    .service-box {
        width: 46%;
    }

    .heading {
        font-size: 18px;
        padding: 15px 15px 0 15px;
    }

    .services-div {
        gap: 34px;
    }

    .service-text {

        font-size: 14px;
    }
    .bullet {     
        width: 40px;
        height: 40px;
        font: 300 22px/42px 'Montserrat', sans-serif;
       
    }
}

@media only screen and (max-width: 555px) {
    .services-text {
        padding: 12px;
        font-size: 15px;
    }

    .service-box {
        width: 100%;

    }

    .heading {
        font-size: 22px;
        padding: 15px 15px 0 15px;
    }

    .services-div {
        gap: 34px;
    }

    .service-text {

        font-size: 12px;
    }
    .p-step{
        font-size: 10px;
    }
}