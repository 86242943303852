.footerContainer {
    background-color: #000000;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
 
}

.footerWrap {
    padding: 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
    height: 74px;

}

.socialMedia {
    max-width: 1000px;
    width: 100%;
}

.socialMediaWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;

}

.socialLogo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.websiteRights {
    color: #fff;

}

.socialIcons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}

.socialIconLink {
    color: #fff;
    font-size: 24px;
}

@media only screen and (max-width: 1024px) {
    .footerWrap {
        padding: 15px 10px;
        height: 70px;   
    }
    .socialLogo{
        font-size: 22px;
    }
    .websiteRights{
        font-size: 12px;
    }
    .socialIcons{
        width: 180px;
    }
    .socialIconLink{
        font-size: 20px;
    }

}

@media only screen and (max-width: 600px) {
    .footerWrap {
        padding: 15px 10px;
        
        height: 74px;
    
    }
    .socialLogo{
        font-size: 18px;
    }
    .websiteRights{
        font-size: 8px;
        padding-left: 17px;
    }
    .socialIcons{
        width: 130px;
    }
    .socialIconLink{
        font-size: 18px;
    }

}
