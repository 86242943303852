.about-image {
    width: 100%;
    height: 400px;
    top: 79px;
    position: absolute;
    background: url("../../../src//assets/programming-background-with-person-working-with-codes-computer\ (3\).jpg") center center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the color and opacity as needed */
    z-index: 1;
}

.about-image .content {
    position: relative;
    z-index: 2;
    color: white; /* Adjust text color as needed */
    /* Add other styles for the content inside the overlay */
}

.about-heading {
    color: #fff;
    font-size: 40px;
    display: flex;
    justify-content: space-evenly;
    padding-top: 94px;
    z-index: 2;
}

.about-div {
    padding-top: 440px;

    font: normal 600 34px/40px 'Montserrat', sans-serif;
    text-align: center;
}

.about-text {
    padding: 20px 100px 0 100px;
    margin-bottom: 0;
    margin-top: 0;
}

.about-text-head {
    margin-bottom: -20px;
}


.about-text-div {
    position: relative;
    float: left;
    padding: 15px 20px;
    background: #fff;
    height: 599px;
}



h1 {
    color: #333;
    font-size: 24px;
    text-align: center;
}

.about-text {
    color: #666;
    font-size: 16px;
    line-height: 1.5;

}

.section-heading {
    color: #333;
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.feature-list {

    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 160px;
}

.feature-list li {
    list-style: disc;
    margin-bottom: 10px;
}

.why-choose-us {
    margin-top: 20px;
}

.why-choose-us .list-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.why-choose-us .list-item i {
    color: #666;
    font-size: 18px;
    margin-right: 10px;
}

.footer {
    margin-top: 20px;
    text-align: center;
}

.footer p {
    color: #666;
    font-size: 14px;
    margin-top: 4rem;
}

@media only screen and (max-width: 1024px) {
    .about-heading {
        font-size: 25px;

    }
    .services-div{
        padding: 452px 65px 65px 65px !important;
    }

    .about-div {
        padding-top: 330px;
        font: normal 600 26px/29px 'Montserrat', sans-serif;
    }

    .about-text {
        font-size: 12px;
        padding: 5px 40px 0 40px;
    }

    .about-text-div {
        padding: 15px 0px;
    }

    .footer p {
        font-size: 10px;
        margin-top: 2rem;
    }

}

@media only screen and (max-width: 600px) {
    .about-heading {
        font-size: 25px;

    }

    .about-div {
        padding-top: 360px;
        font: normal 600 26px/29px 'Montserrat', sans-serif;
    }

    .about-text {
        font-size: 12px;
        padding: 5px 40px 0 40px;
    }

    .about-text-div {
        padding: 40px 0px;
    }

    .footer p {

        font-size: 10px;
        margin-top: 2rem;
    }
    .services-div{
        padding: 452px 65px 65px 65px !important;
    }
    .about-image {
        height: 450px;
    top: 123px;

    }
    .about-images {
        height: 450px;
    top: 123px;

    }

}