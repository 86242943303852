.career-container {
    position: relative;
    min-height: 100vh;
    background-color: #f8f9fa;
}

.career-hero {
    width: 100%;
    height: 400px;
    position: relative;
    background: url("../../../src/assets/programming-background-with-person-working-with-codes-computer (3).jpg") center center/cover no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.career-hero.small {
    height: 300px;
}

.career-hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}

.career-hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: #fff;
}

.career-heading {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 16px;
    background: linear-gradient(
        to right,
        #fff 0%,
        #f0f0f0 30%,
        #fff 50%,
        #f0f0f0 70%,
        #fff 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 200% auto;
    animation: textShine 3s linear infinite;
}

.career-subheading {
    font-size: 24px;
    color: #f0f0f0;
    font-weight: 300;
}

.career-content {
    padding: 60px 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.career-intro {
    text-align: center;
    margin-bottom: 40px;
}

.career-intro h2 {
    font-size: 36px;
    color: #1d272b;
    margin-bottom: 16px;
}

.career-intro p {
    font-size: 18px;
    color: #666;
}

.jobs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 20px 0;
}

.job-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    overflow: hidden;
}

.job-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.15);
}

.job-card-content {
    padding: 25px;
}

.job-card-title {
    font-size: 20px;
    color: #1d272b;
    margin-bottom: 15px;
}

.job-card-details {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}

.job-detail {
    font-size: 14px;
    color: #666;
    padding: 4px 12px;
    background: #f8f9fa;
    border-radius: 20px;
}

.job-card-description {
    color: #666;
    font-size: 14px;
    line-height: 1.6;
    margin-bottom: 20px;
}

.view-details-btn {
    background-color: transparent;
    color: #1d272b;
    border: 2px solid #1d272b;
    padding: 8px 20px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-details-btn:hover {
    background-color: #1d272b;
    color: #fff;
}

/* Job Details Styles */
.job-details-content {
    padding: 40px;
    max-width: 800px;
    margin: 0 auto;
}

.job-details-card {
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
}

.requirement-block {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 25px;
    transition: transform 0.3s ease;
    animation: slideIn 0.5s ease forwards;
}

.requirement-block:hover {
    transform: translateY(-2px);
}

.requirement-block h3 {
    color: #1d272b;
    font-size: 20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid rgba(29, 39, 43, 0.1);
}

.requirement-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.requirement-item {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    color: #4a5568;
    line-height: 1.6;
}

.requirement-item:last-child {
    margin-bottom: 0;
}

.requirement-item::before {
    content: "→";
    position: absolute;
    left: 0;
    color: #1d272b;
    font-weight: bold;
}

.apply-section {
    display: flex;
    gap: 20px;
    margin-top: 40px;
}

.apply-button, .back-button {
    padding: 12px 30px;
    border-radius: 6px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.apply-button {
    background-color: #1d272b;
    color: #fff;
    border: none;
    flex: 2;
    animation: pulse 2s infinite;
}

.back-button {
    background-color: transparent;
    color: #1d272b;
    border: 2px solid #1d272b;
    flex: 1;
}

.apply-button:hover {
    background-color: #2c3e45;
}

.back-button:hover {
    background-color: #1d272b;
    color: #fff;
}

/* Animations */
@keyframes textShine {
    to {
        background-position: 200% center;
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(29, 39, 43, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(29, 39, 43, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(29, 39, 43, 0);
    }
}

/* Loading State */
.loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #1d272b;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 1024px) {
    .career-content {
        padding: 40px 20px;
    }

    .job-details-content {
        padding: 20px;
    }

    .career-heading {
        font-size: 40px;
    }

    .career-subheading {
        font-size: 20px;
    }
}

@media (max-width: 768px) {
    .career-hero {
        height: 300px;
    }

    .career-heading {
        font-size: 32px;
    }

    .career-subheading {
        font-size: 18px;
    }

    .job-details-card {
        padding: 20px;
    }

    .apply-section {
        flex-direction: column;
    }

    .back-button {
        order: -1;
    }

    .requirement-block {
        padding: 20px;
    }
}

@media (max-width: 480px) {
    .career-hero {
        height: 250px;
    }

    .career-heading {
        font-size: 28px;
    }

    .career-subheading {
        font-size: 16px;
    }

    .jobs-grid {
        grid-template-columns: 1fr;
    }

    .job-card-content {
        padding: 20px;
    }

    .job-detail {
        font-size: 12px;
    }

    .requirement-block {
        padding: 15px;
    }

    .requirement-item {
        font-size: 14px;
    }
}