/* nav {
    position: relative;
    background-color: #1d272b;  
    padding: 10px;
    z-index: 1;

}

.hamburger-icon {
    cursor: pointer;
    color: #f1f1f1;
    display: flex;
    justify-content: flex-end;
}

.navbar-list {
    position: absolute;
    top: 100%;
    right: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    width: 180px;
}

.navbar-list.visible {
    display: block;
}

.navbar-list li {
    padding: 5px 10px;
}


.listNavbar-container {
    position: relative;
    z-index: 1;
    background-color: #1d272b;
    width: 100%;
    padding: 20px 30px;
}

.listNavbar-text {
    font-size: 20px;
    cursor: pointer;
    color: #f1f1f1;
    font-family: Georgia, serif;
    margin-top: 20px;
    text-decoration: underline;
}


.listNavbar-close {
    color: #f1f1f1;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.listNavbar-text.disabled {
    cursor: not-allowed;
    opacity: 0.6;
    pointer-events: none;
  }
  @media only screen and (max-width: 600px) {


    .listNavbar-text {
        font-size: 12px;
        cursor: pointer;
        color: #f1f1f1;
        font-family: Georgia, serif;
        margin-top: 20px;
        text-decoration: underline;
    }

  }
  @media only screen and (max-width: 1024px) {
    .listNavbar-text {
        font-size: 16px;
        cursor: pointer;
        color: #f1f1f1;
        font-family: Georgia, serif;
        margin-top: 20px;
        text-decoration: underline;
    }

  }
  */
  .navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    background-color: #02050e;
    position: fixed;
    z-index: 1000;
}

.navbar-div {
    width: 268px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 50px;
}

.nav-image {
    width: 100%;
}

.nav-logo {
    width: 100%;
}

.hamburger-icon {
    font-size: 24px;
    cursor: pointer;
    display: none;
    padding-left: 20px;
    color: white; /* Add color to the hamburger icon */
}

.nav-item {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 440px;
}

.items {
    background: linear-gradient(45deg,
            #999 5%,
            #fff 10%,
            #ccc 30%,
            #ddd 50%,
            #ccc 70%,
            #fff 80%,
            #999 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}

.nav-item-quote {
    display: inline-block;
    padding: 10px;
    border-radius: 12px;
    border: 1px solid white;
    background: linear-gradient(45deg,
            #999 5%,
            #fff 10%,
            #ccc 30%,
            #ddd 50%,
            #ccc 70%,
            #fff 80%,
            #999 95%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    margin-right: 50px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .nav-item {
        display: none;
        flex-direction: column;
        width: 100%;
        text-align: center;
        padding-top: 150px;
        gap: 0px;
    }

    .nav-item.open {
        display: flex;
        z-index: 2;
    }

    .hamburger-icon {
        display: block;
    }

    .items {
        margin: 10px 0;
    }

    .nav-item-quote {
        width: 60%;
        text-align: center;
        margin: 10px 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .navbar-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 125px;
        background-color: #02050e;
    }

  }