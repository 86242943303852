.hero-container {
  background: url('../../assets/wallpaperflare.com_wallpaper\ \(12\)\ \(1\).jpg') center center/cover no-repeat;
  height: 92vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container>h1 {
  background: linear-gradient(
    to right,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 90%,
    #999 95%
);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
background-size: 200% auto;
animation: textShine 4s ease-in infinite forwards;
font-size: 65px;
font-weight: 400;
margin-top: 99px;
;
    /* text-transform: uppercase; */


} 

.hero-container>p {
  background: linear-gradient(
    to right,
    #999 5%,
    #fff 10%,
    #ccc 30%,
    #ddd 50%,
    #ccc 70%,
    #fff 90%,
    #999 95%
);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-fill-color: transparent;
background-size: 200% auto;
animation: textShine 4s ease-in infinite forwards;
font-size: 65px;
font-weight: 100;
  /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif; */
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.home-carousel {
  width: 100%;
  
}
@media screen and (max-height: 980px) {
  .home-carousel{
    position: absolute;
    top: 40%;

  }
}
@media screen and (max-height: 920px) {
  .home-carousel{
    position: absolute;
    top: 43%;

  }
}
@media screen and (max-height: 860px) {
  .home-carousel{
    position: absolute;
    top: 45%;

  }
}
@media screen and (max-height: 800px) {
  .home-carousel{
    position: absolute;
    top: 48%;

  }
}
@media screen and (max-height: 760px) {
  .home-carousel{
    position: absolute;
    top: 50%;

  }
}
@media screen and (max-height:700px) {
  .home-carousel{
    position: absolute;
    top: 52%;

  }
}
@media screen and (max-width: 960px) {
  .hero-container>h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container>h1 {
    font-size: 50px;
    margin-top: -120px;
  }

  .hero-container>p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .hero-container>h1 {
    font-size: 40px;
    /* margin-top: -200px; */
  }

  .hero-container>p {
    font-size: 20px;
  }

  .hero-btns .btn {
    margin: 6px;
    font-size: 15px;
  }
}

@keyframes textShine {
  to {
    background-position: 200%;
  }
}
